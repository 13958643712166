import React from 'react'
import { graphql } from 'gatsby'

import file from '../content/settings/settings.json'

import Layout from '../layout'

const Career = ({
  data: {
    careersJson: {
      description,
      metaTags,
      fields: { slug },
      title,
      shrink_font,
      tabs
    },
    settingsJson: {
      page_templates: { career }
    }
  },
  location
}) => {
  const hero = {
    template: 'main-hero',
    text: title,
    image_or_video: 'image',
    display_button: true,
    size: shrink_font ? 'small' : 'large',
    button: {
      label: 'Read more',
      button_type: 'Function',
      function: 'Scroll Down'
    },
    image: career.hero_image,
    background_text: career.background_text,
    logo: 'light'
  }

  const textTabs = {
    template: 'main-text-tabs',
    title,
    tabs: [...tabs.map(tab => ({ title: tab.label, html: tab.content })), { title: 'Apply online', form: file.careerForm }],
    logo: 'dark'
  }

  const banner = {
    template: 'main-banner',
    text: career.banner_text,
    background_text: career.banner_background_text,
    image: career.banner_background_image,
    logo: 'light'
  }

  return <Layout location={location} image={career.image} title={title} description={description} metaTags={metaTags} slug={slug} components={[hero, textTabs, banner]} />
}

export const query = graphql`
  query ($id: String) {
    careersJson(id: { eq: $id }) {
      description
      # metaTags
      fields {
        slug
      }
      tabs {
        content
        label
      }
      title
      shrink_font
    }
    settingsJson(fields: { slug: { eq: "/settings/settings/" } }) {
      page_templates {
        career {
          image
          hero_image
          banner_text
          banner_background_image
          banner_background_text
          background_text
        }
      }
    }
  }
`

export default Career
